<template>
  <DashboardTemplate>
    <div id="community">
      <div class="header">
        <div class="logo-box">
          <el-image :src="require('../assets/logo1.png')" fit="cover" />
          <el-image :src="require('../assets/logo2.png')" fit="cover" />
          <el-image :src="require('../assets/logo3.png')" fit="cover" />
        </div>
        <p>
          โครงการวิจัย การค้นหาอัตลักษณ์ชุมชนผ่านวัฒนธรรม ประเพณี และวิถีชีวิต
          ต.บ้านถ้ำ อ.ดอกคำใต้ จ.พะเยา
        </p>
        <p>
          ภายใต้โครงการอนุรักษ์พันธุกรรมพืชอันเนื่องจากพระราชดำริสมเด็จพระเทพรัตนราชสุดาฯ
          สยามบรมราชกุมารี สนองพระราชดำริโดย มหาวิทยาลัยพะเยา (อพ.สธ.)
        </p>
      </div>
      <el-row :gutter="20">
        <el-col :span="5">
          <div class="menu-box">
            <div class="list">
              <p @click="goToTab('1')" :class="{ active: tab === '1' }">SWOT</p>
              <p @click="goToTab('2')" :class="{ active: tab === '2' }">
                อัตลักษณ์ชุมชน
              </p>
              <p @click="goToTab('3')" :class="{ active: tab === '3' }">
                หลักฐานทางประวัติศาสตร์
              </p>
              <p @click="goToTab('4')" :class="{ active: tab === '4' }">
                ชุดภาพกู่เก่า
              </p>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <Swot v-if="tab === '1'" />
          <Identity v-if="tab === '2'" />
          <History v-if="tab === '3'" />
          <SetOfPictures v-if="tab === '4'" />
        </el-col>
      </el-row>
    </div>
  </DashboardTemplate>
</template>

<script>
import DashboardTemplate from "@/template/DashboardTemplate.vue";

import Swot from "@/components/comunityC1/Swot.vue";
import Identity from "@/components/comunityC1/Identity.vue";
import History from "@/components/comunityC1/History.vue";
import SetOfPictures from "@/components/comunityC1/SetOfPictures.vue";

export default {
  components: {
    DashboardTemplate,

    Swot,
    Identity,
    History,
    SetOfPictures,
  },
  data() {
    return {
      tab: "1",
    };
  },
  watch: {
    "$route.params.tab"() {
      this.setActiveTab();
    },
  },
  mounted() {
    this.setActiveTab();
  },
  methods: {
    setActiveTab() {
      this.tab = this.$route.params.tab;
    },
    goToTab(key) {
      this.$router.push(`/community/c1/${key}`).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/element-variables.scss";

#community {
  padding: 20px 10vw 20px 10vw;
  .header {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
    .logo-box {
      display: flex;
      justify-content: center;
      align-content: center;
      .el-image {
        width: 100px;
        margin: 0 10px;
      }
    }
  }
  .menu-box {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;

    .list {
      font-size: 14px;
      padding: 20px;
      p {
        margin: 0;
        padding-bottom: 10px;
        cursor: pointer;
        &:last-child {
          padding-bottom: 0;
        }
        &:hover,
        &.active {
          color: $--color-primary;
        }
      }
    }
  }

  .el-row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>